<template>

    <div>
        <div style="width:400px;margin:20px">
            <Form  :label-width="100">
                <FormItem label="原始密码：">
                    <Input placeholder="请输入原始密码"  v-model="submitForm.pwd" ></Input>
                </FormItem>
                <FormItem label="新密码：">
                    <Input placeholder="请输入新密码"  v-model="submitForm.newpwd" ></Input>
                </FormItem>
                <FormItem label="再次输入：">
                    <Input placeholder="请再次输入"  v-model="submitForm.again" ></Input>
                </FormItem>

            </Form>
            <div style="margin-left: 100px">
                <Button @click="subBtn" type="warning">保存</Button>
            </div>
        </div>

    </div>
</template>

<script>
    import { modifyPwd } from "@/api/index";
    export default {
        data(){
            return{
                submitForm:{
                    pwd:"",
                    newpwd:"",
                    again:""
                }
            }
        },
        created(){
        },
        methods:{
            subBtn(){
                if (this.submitForm.pwd == "" ||　this.submitForm.newpwd == "" || this.submitForm.again == "") {
                    this.$Message.error("数据不能为空");return;
                }
                if (this.submitForm.newpwd != this.submitForm.again) {
                    this.$Message.error("两次输入的密码不对");return;
                }
                modifyPwd(this.submitForm).then(res=>{
                    this.$Message.success(res.msg)
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            }
        }
    }
</script>

<style scoped>

</style>